import React,{useState} from 'react'
import { Disclosure } from '@headlessui/react'
import FontDesign from './fontDesign';
import ButtonDesign from './buttonDesign';
import HeaderDesign from './headerDesign';
import ContentAreaDesign from './contentAreaDesign';
import FooterDesign from './footerDesign';
import PaddleDesign from './paddleDesign';

export default function DesignSection({auctionData, setAuctionData, setEditedAuctionData}) {
    return (
        <>
            {/* <!-- font contents --> */}
            <Disclosure as="div" defaultOpen={true} className="px-9 pt-4 border-b pb-[24px]">
                {({ open }) => (
                    <>
                        <Disclosure.Button className="flex justify-between w-full items-center">
                            <p className="font-medium text-[#343434] text-[14px] leading-normal">Font</p>
                            <img 
                                src={open?"/images/auction-hide-dropdown.svg":
                                    "/images/auction-show-dropdown.svg"} 
                                className="cursor-pointer header-dropdown" 
                                alt="dropdown-icon" loading="lazy" />
                        </Disclosure.Button>
                        <Disclosure.Panel className="auction-rule-content">
                            <FontDesign auctionData={auctionData} 
                                setAuctionData={setAuctionData} setEditedAuctionData={setEditedAuctionData} />
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
            {/* <!-- buttons --> */}
            <Disclosure as="div" defaultOpen={true} className="px-9 pt-4 border-b pb-[24px]">
                {({ open }) => (
                    <>
                        <Disclosure.Button className="flex justify-between w-full items-center">
                            <p className="font-medium text-[#343434] text-[14px] leading-normal">Buttons</p>
                            <img src={open?"/images/auction-hide-dropdown.svg":"/images/auction-show-dropdown.svg"} 
                                className="cursor-pointer header-dropdown" 
                                alt="dropdown-icon" loading="lazy" />
                        </Disclosure.Button>
                        {/* <!-- color content --> */}
                        <Disclosure.Panel className="button-content">
                            <ButtonDesign auctionData={auctionData} 
                                setAuctionData={setAuctionData} setEditedAuctionData={setEditedAuctionData} />
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
            {/* <!-- header --> */}
            <Disclosure as="div" defaultOpen={true} className="px-9 pt-4 border-b pb-[24px]">
                {({ open }) => (
                    <>
                        <Disclosure.Button className="flex justify-between w-full items-center">
                            <p className="font-medium text-[#343434] text-[14px] leading-normal">Header</p>
                            <img src={open?"/images/auction-hide-dropdown.svg":"/images/auction-show-dropdown.svg"} 
                                className="cursor-pointer header-dropdown" 
                                alt="dropdown-icon" loading="lazy" />
                        </Disclosure.Button>
                        {/* <!-- color content --> */}
                        <Disclosure.Panel className="header-content">
                            <HeaderDesign auctionData={auctionData} 
                                setAuctionData={setAuctionData} setEditedAuctionData={setEditedAuctionData} />
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
            {/* <!-- content area --> */}
            <Disclosure as="div" defaultOpen={true} className="px-9 pt-4 border-b pb-[24px]">
                {({ open }) => (
                    <>
                        <Disclosure.Button className="flex justify-between w-full items-center">
                            <p className="font-medium text-[#343434] text-[14px] leading-normal">Content area</p>
                            <img src={open?"/images/auction-hide-dropdown.svg":"/images/auction-show-dropdown.svg"} 
                                className="cursor-pointer header-dropdown" 
                                alt="dropdown-icon" loading="lazy" />
                        </Disclosure.Button>
                        {/* <!-- color content --> */}
                        <Disclosure.Panel className="contents">
                            <ContentAreaDesign auctionData={auctionData} 
                                setAuctionData={setAuctionData} setEditedAuctionData={setEditedAuctionData} />
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
            {/* <!-- footer area --> */}
            <Disclosure as="div" defaultOpen={true} className="px-9 pt-4 border-b pb-[24px]">
                {({ open }) => (
                    <>
                        <Disclosure.Button className="flex justify-between w-full items-center">
                            <p className="font-medium text-[#343434] text-[14px] leading-normal">Footer</p>
                            <img src={open?"/images/auction-hide-dropdown.svg":"/images/auction-show-dropdown.svg"} 
                                className="cursor-pointer header-dropdown" 
                                alt="dropdown-icon" loading="lazy" />
                        </Disclosure.Button>
                        {/* <!-- color content --> */}
                        <Disclosure.Panel className="footer-content">
                            <FooterDesign auctionData={auctionData} 
                                setAuctionData={setAuctionData} setEditedAuctionData={setEditedAuctionData} />
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
            {/* <!-- paddle area --> */}
            <Disclosure as="div" defaultOpen={true} className="px-9 pt-4  pb-[24px]">
                {({ open }) => (
                    <>
                        <Disclosure.Button className="flex justify-between w-full items-center">
                            <p className="font-medium text-[#343434] text-[14px] leading-normal">Paddle</p>
                            <img src={open?"/images/auction-hide-dropdown.svg":"/images/auction-show-dropdown.svg"} 
                                className="cursor-pointer header-dropdown" 
                                alt="dropdown-icon" loading="lazy" />
                        </Disclosure.Button>
                        <Disclosure.Panel className="paddle-content">
                            <PaddleDesign auctionData={auctionData} 
                                setAuctionData={setAuctionData} setEditedAuctionData={setEditedAuctionData} />
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </>
    )
}
