/* eslint-disable security/detect-object-injection */
import { createSlice } from '@reduxjs/toolkit';

const initialState={
    first_name: '',
    last_name: '',
    email_address: '',
    brand_name: '',
    business_registration_number : '',
    date_of_birth: '',
    address_line_1: '',
    address_line_2: '',
    city: '',
    postal_code: '',
    country: '',
    phone_number: '',
    country_code: '',
    website: '',
    facebook_link: '',
    Instagram_link: '',
    twitter: '',
    linkedin_link: '',
    tiktok_link: '',
    stripe_status: '',
    seller_id: '',
    website_error: null,
    facebook_error: null,
    instagram_error: null,
    twitter_error: null,
    linkedin_error: null,
    tiktok_error: null,
}

/* The code is creating a Redux slice using the `createSlice` function from the `@reduxjs/toolkit`
library. */
const profileSlice= createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setFieldValue: (state, action) => {
            const { field, value } = action.payload;
            state[field] = value;
        },
        setInitialState: (state, action) => {
            const data=action.payload;
            return {
                first_name: data.first_name,
                last_name: data.last_name,
                email_address: data.email_address,
                brand_name: data.brand_name,
                business_registration_number : data.business_registration_number,
                date_of_birth: data.date_of_birth,
                address_line_1: data.address_line_1,
                address_line_2: data.address_line_2,
                city: data.city,
                postal_code: data.postal_code,
                country: data.country,
                country_code: data.country_code,
                phone_number: data.phone_number,
                website: data.website,
                facebook_link: data.facebook_link,
                Instagram_link: data.Instagram_link,
                twitter: data.twitter,
                linkedin_link: data.linkedin_link,
                tiktok_link: data.tiktok_link
            }
        },
        clearData: (state) => {
            Object.assign(state, initialState);
        }
    }
})

export const { setFieldValue, setInitialState, clearData } = profileSlice.actions;

export default profileSlice.reducer;