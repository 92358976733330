/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';

export default function FlagBanner({sblogin,SellerLogin,logValue}) {
    const [message, setMessage] = useState('');
    const [isTextExpanded, setIsTextExpanded] = useState(false);
    const [maxLength, setMaxLength] = useState(30); // Default for mobile

    const toggleText = () => setIsTextExpanded((prev) => !prev);

    useEffect(() => {
        // Set maxLength based on screen size (mobile vs desktop)
        const updateMaxLength = () => {
            if (window.innerWidth >= 768) {
                setMaxLength(100); // For medium and larger screens
            } else {
                setMaxLength(30); // For mobile screens
            }
        };

        updateMaxLength(); // Initial check
        window.addEventListener('resize', updateMaxLength); // Listen to resize events

        // Clean up the event listener on unmount
        return () => window.removeEventListener('resize', updateMaxLength);
    }, []);

    // Determine whether to show full or truncated message
    const displayMessage = isTextExpanded || message.length <= maxLength 
        ? message 
        : message.substring(0, maxLength) + '...';

    const buttonText = isTextExpanded ? 'Read less' : 'Read more';


    useEffect(() =>{
        if(sblogin===true || SellerLogin=== true){
            setMessage('Seller registration and login to the application are currently disabled.')
        }
        // If logValue is true, start the 2-minute timer
        if (logValue) {
            setMessage('The specified seller email is currently disabled. Please contact support for assistance.');
        }
        
         
    },[logValue])

    
    return (
       
        <div>
            <div className={`fixed  bg-[#B8D5DB] text-center text-[#282828]
             text-[14px] leading-[17px] font-medium font-Medium w-full top-[2.5rem] px-2 py-4 mb-5 z-[9999]`}>
                <div className={`justify-center items-center md:flex flex flex-wrap   `}>
                    <p className={`text-[#282828]`} >{displayMessage} </p>
                    {message.length > maxLength && (
                        <button 
                            onClick={toggleText} 
                            className="text-[#282828] underline"
                        >
                            {buttonText}
                        </button>
                    )}
                </div>
            </div>
        </div>
    )
}


//  {auction.title==='' ? '-' :
//                  (auction.title.length > 40 ?auction.title.substring(0, 40) + '...' : auction.title)} 