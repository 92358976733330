/* eslint-disable max-len */
import React, { useState ,  useEffect, useCallback } from 'react'
import { Tab } from '@headlessui/react'
import SetUpHeader from './setUpHeader'
import SetUpDetails from './setUpDetails'
import SetUpAuctionDates from './setUpAuctionDates'
import SetUpAuctionRules from './setUpAuctionRules'
import SetUpSupport from './setUpSupport'
import SetUpPrivacySetting from './setUpPrivacySetting'
import { Disclosure } from '@headlessui/react'
import DesignSection from '../designSection'
import MenuLinkModal from './menuLinkModal'
import { useSelector } from 'react-redux'
import { dataService } from '../../../../../services/dataService'


export default function SetupSection(props) {
    const { text, setText,auctionData,setAuctionData,setEditedAuctionData, handleLogoChange, handleLogoDelete, errorMessages, handleLogoRedirectionChange, addMenuLink, deleteMenuLink,
        addDropdownLink,deleteDropdownLink, newMenuLink, setNewMenuLink, isAddingDropdown, setIsAddingDropdown, 
        setMenuModal, menuModal, dropdownLinkError, setDropdownLinkError,startDate,setStartDate,endDate,setEndDate,startHour,setStartHour,startHourShow, 
        setStartHourShow,startMin,setStartMin,startMinShow, setStartMinShow,endHour,setEndHour,endHourShow,setEndHourShow,endMin,setEndMin,
        endMinShow,setEndMinShow,timezoneShow,setTimezoneShow, menuLinkError,setMenuLinkError,setStartTimestamp, setEndTimestamp,
        logoLoader,setLogoLoader,imageLoader,setImageLoader,invalidDate,setInvalidDate,sameTime,setSameTime,mobileModal, isMobileModal,isBanner} = props
    const user = (localStorage.getItem('user'));
    const [planType , setPlanType] = useState('');
    const isDisabled = planType === 'Starter'; // Add your condition here
    const tooltipMessage = "Upgrade to Pro to customise your auction design";

    const fetchHandler = useCallback(async()=>{
        const body = {
            "email": user
        }
        
        const response = await dataService.ViewProfile(body);
        if(response.error===false){
            setPlanType(response.data.data.data.plan_type)

        }

    },[])

    useEffect(() =>{
        fetchHandler()
    },[])

        
    /**
     * The function `classNames` takes in multiple arguments and returns a string of all the truthy
     * arguments joined together with a space.
     * @returns a string that is the result of joining all the truthy values in the `classes` array
     * with a space character.
     */
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }
    return (
        <>
            {/* <!--Tabs navigation--> */}
            <div className={`min-h-[calc(100vh-100px)] ${isBanner ? 'mt-0': 'mt-[55px]'}`}>
                <Tab.Group>
                    <Tab.List
                        className="mb-5 flex list-none flex-row flex-wrap pl-0"
                        role="tablist"
                        data-te-nav-ref>
                        <Tab role="presentation" id="setup-auction"
                            className={({ selected }) =>
                                classNames("w-[50%] block  text-center lg:px-7 px-3 pb-3.5 pt-4 text-[14px]",
                                    "font-medium leading-normal hover:isolate hover:border-transparent", 
                                    "bg-[#F4F4F5] focus:isolate focus:border-transparent", 
                                    selected ? 'bg-[#ffffff] border-[#D4D4D8] text-[#52525A]' : 'bg-[#F4F4F5] text-[#A1A1A9]')}
                        >Setup Auction
                        </Tab>
                        <Tab role="presentation" id="design"
                            className={({ selected }) =>
                                classNames("w-[50%] block  text-center px-7 pb-3.5 pt-4 text-[14px] font-medium leading-normal", 
                                    "hover:isolate hover:border-transparent data-[te-nav-active]:bg-[#ffffff] bg-[#F4F4F5] focus:isolate",
                                    "focus:border-transparent", selected ? 'bg-[#ffffff] border-[#D4D4D8] text-[#52525A]' : 'bg-[#F4F4F5] text-[#A1A1A9]', isDisabled ? 'opacity-50 relative tooltip-container' : '')}
                            disabled={isDisabled} // You can also use the 'disabled' prop
                            data-tooltip={isDisabled ? tooltipMessage : ""}
                        >
                            Design
                        </Tab>
                       
                    </Tab.List>
                    {/* <!--Tabs content--> */}
                    <Tab.Panels className="mb-6">
                        <Tab.Panel
                            className="hidden opacity-100 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
                            id="tabs-home"
                            role="tabpanel"
                            aria-labelledby="tabs-home-tab"
                            data-te-tab-active>
                            {/* <!-- auction setup contents --> */}
                            <Disclosure as="div" className="px-9 border-b pb-[24px]" defaultOpen={true}>
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button className="flex justify-between w-full items-center">
                                            <p className="font-medium text-[#343434] text-[14px] leading-normal">Header</p>
                                            <img src={open?"/images/auction-hide-dropdown.svg":"/images/auction-show-dropdown.svg"} className="cursor-pointer header-dropdown" 
                                                alt="dropdown-icon" loading="lazy" />
                                        </Disclosure.Button>
                                        <Disclosure.Panel> 
                                            <SetUpHeader auctionData={auctionData} handleLogoChange={handleLogoChange} handleLogoDelete={handleLogoDelete}
                                                errorMessages={errorMessages} handleLogoRedirectionChange={handleLogoRedirectionChange}
                                                addMenuLink={addMenuLink}
                                                deleteMenuLink={deleteMenuLink}
                                                addDropdownLink={addDropdownLink}
                                                deleteDropdownLink={deleteDropdownLink}
                                                newMenuLink={newMenuLink}
                                                setNewMenuLink={setNewMenuLink}
                                                isAddingDropdown={isAddingDropdown} 
                                                setIsAddingDropdown={setIsAddingDropdown}
                                                menuModal={menuModal}
                                                setMenuModal={setMenuModal}
                                                dropdownLinkError={dropdownLinkError}
                                                setDropdownLinkError={setDropdownLinkError}
                                                startDate={startDate} 
                                                setStartDate={setStartDate}
                                                endDate={endDate} 
                                                setEndDate={setEndDate}
                                                startHour={startHour}
                                                setStartHour={setStartHour}
                                                startHourShow={startHourShow} 
                                                setStartHourShow={setStartHourShow}
                                                startMin={startMin}
                                                setStartMin={setStartMin}
                                                startMinShow={startMinShow} 
                                                setStartMinShow={setStartMinShow}
                                                endHour={endHour}
                                                setEndHour={setEndHour}
                                                endHourShow={endHourShow}
                                                setEndHourShow={setEndHourShow}
                                                endMin={endMin}
                                                setEndMin={setEndMin}
                                                endMinShow={endMinShow}
                                                setEndMinShow={setEndMinShow}
                                                timezoneShow={timezoneShow}
                                                setTimezoneShow={setTimezoneShow}
                                                menuLinkError={menuLinkError} 
                                                setMenuLinkError={setMenuLinkError}
                                                logoLoader={logoLoader}
                                                setLogoLoader={setLogoLoader}
                                                imageLoader={imageLoader}
                                                setImageLoader={setImageLoader}
                                                setInvalidDate={setInvalidDate}
                                                setSameTime={setSameTime}
                                                mobileModal ={mobileModal}
                                                isMobileModal={isMobileModal}
                                            
                                            />                                            

                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                            {/* <!-- details contents --> */}
                            <Disclosure as="div" className="px-9 pt-4 border-b pb-[24px]" defaultOpen={true}>
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button className="flex justify-between w-full items-center">
                                            <p className="font-medium text-[#343434] text-[14px] leading-normal">Details</p>
                                            <img src={open?"/images/auction-hide-dropdown.svg":"/images/auction-show-dropdown.svg"} className="cursor-pointer header-dropdown" 
                                                alt="dropdown-icon" loading="lazy" />
                                        </Disclosure.Button>
                                        <Disclosure.Panel>
                                            <SetUpDetails auctionData={auctionData} handleLogoChange={handleLogoChange} handleLogoDelete={handleLogoDelete}
                                                errorMessages={errorMessages} setAuctionData={setAuctionData} setEditedAuctionData={setEditedAuctionData}
                                                logoLoader={logoLoader}
                                                setLogoLoader={setLogoLoader}
                                                imageLoader={imageLoader} setText={setText} text={text}
                                                setImageLoader={setImageLoader}/>
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                            {/* <!-- AUCTION DATES CONTENT --> */}
                            <Disclosure as="div" className="px-9 pt-4 border-b pb-[24px]" defaultOpen={true}>
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button className="flex justify-between w-full items-center">
                                            <p className="font-medium text-[#343434] text-[14px] leading-normal">Auction dates</p>
                                            <img src={open?"/images/auction-hide-dropdown.svg":"/images/auction-show-dropdown.svg"} className="cursor-pointer header-dropdown" 
                                                alt="dropdown-icon" loading="lazy" />
                                        </Disclosure.Button>
                                        <Disclosure.Panel>
                                            <SetUpAuctionDates auctionData={auctionData} setEditedAuctionData={setEditedAuctionData}
                                                errorMessages={errorMessages} setAuctionData={setAuctionData}
                                                setStartTimestamp={setStartTimestamp}
                                                setEndTimestamp={setEndTimestamp}
                                                startDate={startDate} 
                                                invalidDate={invalidDate}
                                                setInvalidDate={setInvalidDate}
                                                sameTime={sameTime}
                                                setSameTime={setSameTime}
                                                setStartDate={setStartDate}
                                                endDate={endDate} 
                                                setEndDate={setEndDate}
                                                startHour={startHour}
                                                setStartHour={setStartHour}
                                                startHourShow={startHourShow} 
                                                setStartHourShow={setStartHourShow}
                                                startMin={startMin}
                                                setStartMin={setStartMin}
                                                startMinShow={startMinShow} 
                                                setStartMinShow={setStartMinShow}
                                                endHour={endHour}
                                                setEndHour={setEndHour}
                                                endHourShow={endHourShow}
                                                setEndHourShow={setEndHourShow}
                                                endMin={endMin}
                                                setEndMin={setEndMin}
                                                endMinShow={endMinShow}
                                                setEndMinShow={setEndMinShow}
                                                timezoneShow={timezoneShow}
                                                setTimezoneShow={setTimezoneShow}/>
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                            {/* <!-- auction rules contents --> */}
                            <Disclosure as="div" className="px-9 pt-4 border-b pb-[24px]" defaultOpen={true}>
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button className="flex justify-between w-full items-center">
                                            <p className="font-medium text-[#343434] text-[14px] leading-normal">Auction rules</p>
                                            <img src={open?"/images/auction-hide-dropdown.svg":"/images/auction-show-dropdown.svg"} className="cursor-pointer header-dropdown" 
                                                alt="dropdown-icon" loading="lazy" />
                                        </Disclosure.Button>
                                        <Disclosure.Panel>
                                            <SetUpAuctionRules auctionData={auctionData} setEditedAuctionData={setEditedAuctionData}
                                                setAuctionData={setAuctionData} />
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                            {/* <!-- support contents --> */}
                            <Disclosure as="div" className="px-9 pt-4 border-b pb-[24px]" defaultOpen={true}>
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button className="flex justify-between w-full items-center">
                                            <p className="font-medium text-[#343434] text-[14px] leading-normal">Support</p>
                                            <img src={open?"/images/auction-hide-dropdown.svg":"/images/auction-show-dropdown.svg"} className="cursor-pointer header-dropdown" 
                                                alt="dropdown-icon" loading="lazy" />
                                        </Disclosure.Button>
                                        <Disclosure.Panel>
                                            <SetUpSupport auctionData={auctionData}
                                                setAuctionData={setAuctionData} setEditedAuctionData={setEditedAuctionData}/>
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                            {/* <!-- privacy contents --> */}
                            <Disclosure as="div" className="px-9 pt-4" defaultOpen={true}>
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button className="flex justify-between w-full items-center">
                                            <p className="font-medium text-[#343434] text-[14px] leading-normal">Privacy Settings</p>
                                            <img src={open?"/images/auction-hide-dropdown.svg":"/images/auction-show-dropdown.svg"} className="cursor-pointer header-dropdown" 
                                                alt="dropdown-icon" loading="lazy" />
                                        </Disclosure.Button>
                                        <Disclosure.Panel>
                                            <SetUpPrivacySetting auctionData={auctionData}
                                                setAuctionData={setAuctionData} setEditedAuctionData={setEditedAuctionData}/>
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        </Tab.Panel>
                        <Tab.Panel
                            className={`transition-opacity duration-150 ease-linear   ${planType === 'Starter' ? 'opacity-50 pointer-events-none' : 'data-[te-tab-active]:block'}`}
                            id="tabs-profile"
                            role="tabpanel"
                            aria-labelledby="tabs-profile-tab">
                            <DesignSection auctionData={auctionData} setAuctionData={setAuctionData} setEditedAuctionData={setEditedAuctionData} planType={planType}/>
                        </Tab.Panel>
                    </Tab.Panels>
                </Tab.Group>
            </div>
           
        </>
    )
}
